.skills__container {
    display: flex;
    gap: 3rem;
    position: relative;
    flex-direction: row;
    justify-content: center;
    align-content: stretch;
    align-items: stretch;
}

.skill {
    background: rgba(160, 219, 142, 0.1);
    border-radius: 2rem;
    border: 1px solid var(--color-primary-variant);
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-content: stretch;
    align-items: stretch;
}

.skill:hover {
    background: var(--color-bg-variant);
    border-color: var(--color-primary);
    cursor: default;
}

.skill__head {
    position: relative;
    background: var(--color-primary);
    padding: 2rem;
    border-radius: 2rem;
    box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.1);
    z-index: 1;
    cursor: pointer;
}

.skill__head h3 {
    color: var(--color-bg);
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
}

.skill__list {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: left;
}

.skill__list.active {
    height: fit-content;
    width: fit-content;
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: 0.6s ease;
}

.skill__list.inactive {
    height: 0;
    opacity: 0;
    padding: 0;
    visibility: hidden;
    transform: translateY(-3rem);
    transition: 0.6s ease;
    overflow: hidden;
}

.skill__list li {
    display: flex;
    gap: 1rem;
    margin-bottom: 0.8rem;
}

.skill__list-icon {
    color: var(--color-primary);
    margin-top: 2px;
    flex-shrink: 0;
}

.skill__list p {
    font-size: 0.9rem;
}

/* ============== Media Queries (Medium devices) ========== */

@media screen and (max-width: 1024px) {
    .skills__container {
        gap: 1.25rem;
    }
    
    .skill__head{
        cursor: default;
    }
}

/* ============== Media Queries (Small devices) ========== */

@media screen and (max-width: 600px) {
    .skills__container {
        flex-direction: column;
        gap: 1.5rem;
    }
}