.container.testimonials__container {
    width: 50%;
    height: auto;
}

.client__avatar {
    width: 6rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 1rem;
    border: 0.25rem solid var(--color-primary-variant);
}

.swiper {
    height: 100%;
    background: var(--color-bg-variant);
    text-align: center;
    border-radius: 2rem;
    user-select: none;
}

.swiper-slide {
    height: auto !important;
    padding: 2rem;
}

.testimonial {
    display: flex;
    flex: stretch;
    height: 100% !important;
    
}

.client__review {
    color: var(--color-light);
    font-weight: 300;
    display: block;
    width: 80%;
    margin: 0.8rem auto 0;
}

.swiper-slide {
    height: auto;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
    background: var(--color-primary);
}

.autoplay-progress {
    position: absolute;
    right: 16px;
    bottom: 16px;
    z-index: 10;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: var(--color-primary);
  }

.autoplay-progress svg {
    --progress: 0;
    position: absolute;
    left: 0;
    top: 0px;
    z-index: 10;
    width: 100%;
    height: 100%;
    stroke-width: 4px;
    stroke: var(--color-primary);
    fill: none;
    stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
    stroke-dasharray: 125.6;
    transform: rotate(-90deg);
}
.disclaimer {
    width: 50%;
    left: 50%;
    transform: translateX(50%);
    margin-top: 1.25rem;
}

/* ============== Media Queries (Medium devices) ========== */

@media screen and (max-width: 1024px) {
    .container.testimonials__container {
        width: 80%;
    }
    .disclaimer {
        width: 75%;
        transform: translateX(15%);
    }
}

/* ============== Media Queries (Small devices) ========== */

@media screen and (max-width: 600px) {
    .container.testimonials__container {
        width: var(--container-width-sm);
    }
    .client__review {
        width: var(--container-width-sm);
    }

    .disclaimer {
        text-align: justify;
        width: 80%;
        transform: translateX(10%);
        margin-top: 1rem;
    }
}