footer {
    background: var(--color-primary);
    padding: 1rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 5rem;
    position: relative;
}

footer a {
    color: var(--color-bg);
}

.footer__logo {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    display: inline-block;
}

.permalinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto 1rem;
}

.footer__socials {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

.footer__socials a {
    background: var(--color-bg);
    color: var(--color-white);
    padding: 0.8rem;
    border-radius: 0.7rem;
    display: flex;
    border: 1px solid transparent;
}

.footer__socials a:hover {
    background: transparent;
    color: var(--color-bg);
    border-color: var(--color-bg);
}

.footer__copyright {
    margin-bottom: 6rem;
    color: var(--color-bg);
}

.to__top {
    position: absolute;
    right: 2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size:  0.9rem;
    color: var(--color-bg);
}

/* ============== Media Queries (Medium devices) ========== */

@media screen and (max-width: 1024px) {
}

/* ============== Media Queries (Small devices) ========== */

@media screen and (max-width: 600px) {
    .permalinks {
        flex-direction: column;
        gap: 1.5rem;
    }

    .footer__socials {
        margin-bottom: 2.6rem;
    }

    .to__top {
        position: absolute;
        right: 50%;
        bottom: 8.85rem;
        transform: rotate(0deg) translateX(50%);
        font-weight: 300;
        font-size:  0.9rem;
        color: var(--color-bg);
    }
}
