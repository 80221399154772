header {
    height: 100vh;
    /* overflow: hidden; */
}

header section {
    margin-top: 2.25rem;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

/* ========= CTA ========= */

.cta {
    margin-top: 1.25rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* ========= Header Socials ========= */

.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: -2rem;
    bottom: 3rem;
}

.header__socials::after {
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* ======== ME (headshot) ======= */

.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: auto;
    max-width: 100%;
    min-width: 30%;
    height: auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 1.75rem;
    border-radius: 50%;
    overflow: hidden;
    
}

/* ======== Scroll Down ======= */

.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size:  0.9rem;
}

/* ============== Media Queries (Medium devices) ========== */

@media screen and (max-width: 1024px) {
    header {
        height: 65vh;
        padding-top: 1rem;
    }
    
    section {
        margin-top: 8rem;
    }

    .me{
        min-width: 50%;

    }
}

/* ============== Media Queries (Small devices) ========== */

@media screen and (max-width: 600px) {
    header {
        height: 90vh;
    }
    
    .header__socials {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.8rem;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 5.25rem;
    }
    
    .header__socials::after {
        display: none;
    }
    
    .scroll__down {
        display: none;
    }

    .me {
        min-width: 80%;
    }
}